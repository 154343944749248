import React from 'react';
import styled from '@emotion/styled';

import { mqMin } from '@sentry/static-shared/utils/css';
import { white } from '@sentry/static-shared/utils/css/colors';
import { borderRadius } from '@sentry/static-shared/utils/css/constants';
import Layer from '@sentry/static-shared/components/Layer';
import Link from '@sentry/static-shared/components/link';
import Prose from '@sentry/static-shared/components/Prose';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { useLocation } from "@reach/router";
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';
import DocHeader from 'components/doc-header';

export const frontmatter = {
  title: 'Repos',
  subtitle: 'Source Codes and Where to Find Them',
  excerpt:
    'Peek behind the scenes to see how we divide up our codebase.',
  order: 2,
};

const StyledImg = styled.img`
  width: 100%;
`;

const sentry = 'https://github.com/getsentry/sentry';

const repoCategories = [
  {
    name: 'Core',
    description:
      'There are parts of Sentry that make no sense apart from Sentry.',
    repos: [
      'sentry',
      'self-hosted',
      'sentry-docs',
      'relay',
      'snuba',
      'snuba-sdk',
    ],
  },
  {
    name: 'SDKs',
    description:
      'We have many SDKs, some of which we pay closer attention to than others.',
    repos: [
      'sentry-javascript',                 // 6500 ✩s
      'sentry-php',                        // 1700
      'sentry-python',                     // 1308
      'sentry-react-native',               // 1295
      'sentry-laravel',                    // 1100
      'sentry-cocoa',                      //  561
      'sentry-dart',                       //  575
      'sentry-dotnet',                     //  410
      'sentry-java',                       //  862
      'sentry-native',                     //  253
      'sentry-ruby',                       //  837
      'sentry-symfony',                    //  610
      'sentry-unity',                      //  129
      'sentry-android-gradle-plugin',      //   74
      'sentry-javascript-bundler-plugins', //   74
      'sentry-wizard',                     //  113
    ],
  },
  {
    name: 'Extras',
    description:
      'Sharing is caring, and we care. These are tools that we use, and you can, too.',
    repos: [
      'responses',
      'symbolic',
      'symbolicator',
      'craft',
      'arroyo',
      'cdc',
      'wal2json',
    ],
  },
];

const nRepos = repoCategories.map(a => a.repos.length).reduce((a,b) => a+b, 0);

const teams = [
  [
    'Frontend',
    'app-frontend',
    <>
      <a href={sentry}>
        <code>sentry</code>
      </a>{' '}
      — cross-cutting patterns and components
    </>,
  ],

  [
    'Workflow',
    'workflow',
    <>
      <a href={sentry}>
        <code>sentry</code>
      </a>{' '}
      — Issues, Issue Details, Releases
    </>,
  ],

  [
    'Visibility',
    'visibility',
    <>
      <a href={sentry}>
        <code>sentry</code>
      </a>{' '}
      — Discover, Performance, Dashboards
    </>,
  ],

  [
    'Ecosystem',
    'ecosystem',
    <>
      <a href={sentry}>
        <code>sentry</code>
      </a>{' '}
      — Integrations
    </>,
  ],

  [
    'Enterprise',
    'enterprise',
    <>
      <a href={sentry}>
        <code>sentry</code>
      </a>{' '}
      — Authentication, Audit Log, Onboarding
    </>,
  ],

  [
    'Revenue',
    'revenue',
    <>
      <a href={sentry}>
        <code>sentry</code>
      </a>{' '}
      — Billing, Stats
    </>,
  ],

  [
    'SDKs - Web Frontend',
    'team-web-sdk-frontend',
    <>
      <a href="https://github.com/getsentry/sentry-javascript">
        <code>sentry-javascript</code>
      </a>
      , etc.
    </>,
  ],

  [
    'SDKs - Web Backend',
    'team-web-sdk-backend',
    <>
      <a href="https://github.com/getsentry/sentry-python">
        <code>sentry-python</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/sentry-ruby">
        <code>sentry-ruby</code>
      </a>
      , etc.
    </>,
  ],

  [
    'SDKs - Mobile',
    'team-mobile',
    <>
      <a href="https://github.com/getsentry/sentry-java">
        <code>sentry-java</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/sentry-cocoa">
        <code>sentry-cocoa</code>
      </a>
      , etc.
    </>,
  ],

  [
    'SDKs - Native',
    'owners-native',
    <>
      <a href="https://github.com/getsentry/sentry-native">
        <code>sentry-native</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/symbolicator">
        <code>symbolicator</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/symbolic">
        <code>symbolic</code>
      </a>
    </>,
  ],

  [
    'Ingest',
    'ingest',
    <>
      <a href="https://github.com/getsentry/relay">
        <code>relay</code>
      </a>
    </>,
  ],

  [
    'Search & Storage',
    'sns',
    <>
      <a href="https://github.com/getsentry/snuba">
        <code>snuba</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/snuba-sdk">
        <code>snuba-sdk</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/cdc">
        <code>cdc</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/arroyo">
        <code>arroyo</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/wal2json">
        <code>wal2json</code>
      </a>
    </>,
  ],

  ['Growth', 'growth', 'Sandbox, Demos'],

  ['Data', 'data', <>business dashboards</>],

  [
    'Docs',
    'docs',
    <>
      <a href="https://github.com/getsentry/sentry-docs">
        <code>sentry-docs</code>
      </a>
    </>,
  ],

  [
    'Open Source',
    'open-source',
    <>
      <a href="https://github.com/getsentry/self-hosted">
        <code>self-hosted</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/eng-pipes">
        <code>eng-pipes</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/.github">
        <code>.github</code>
      </a>
    </>,
  ],

  [
    'Productivity',
    'productivity',
    <>
      <a href="https://github.com/getsentry/sentry">
        <code>sentry</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/freight">
        <code>freight</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/eng-pipes">
        <code>eng-pipes</code>
      </a>
      , CI/CD
    </>,
  ],

  [
    'Security',
    'security',
    <>
      <a href="https://github.com/getsentry/sentry">
        <code>sentry</code>
      </a>
      ,{' *'}
    </>,
  ],

  [
    'Ops',
    'ops',
    <>
      <a href="https://develop.sentry.dev/sentry-vs-getsentry/">
        <code>getsentry</code>
      </a>
      ,{' '}
      <a href="https://github.com/getsentry/freight">
        <code>freight</code>
      </a>
    </>,
  ],
];

const ReposPage = () => {
  const locationSlug = useLocation();
  const canonicalLink = canonicalURL('open', locationSlug.pathname);
  return (
    <Layout>
      <SEO 
        title={frontmatter.title} 
        description={frontmatter.excerpt}
        canonical={canonicalLink}
      />
      <DocHeader />
      <Layer disablePaddingTop={true} background="light" tag="main">
        <StyledBackplate>
          <Prose>
            <h1>{frontmatter.title}</h1>

            <p>
              Sentry has{' '}
              <a href="https://github.com/search?o=desc&q=org%3Agetsentry+is%3Apublic+archived%3Afalse+fork%3Afalse&s=stars&type=Repositories">
                almost 300 repositories
              </a>{' '}
              on GitHub—active, public repos, not counting forks. Here are the
              {' '}{nRepos} repos that we care enough about to bring under{' '}
              <Link to="/triage/">our triage policy</Link>, grouped by category
              and sorted by number of stars, descending:
            </p>

            <RepoCategories>
              {repoCategories.map(({ name, description, repos }) => {
                return (
                  <div>
                    <h3>{name}</h3>
                    <p>{description}</p>
                    <ol>
                      {repos.map(repo => {
                        const href = 'https://github.com/getsentry/' + repo;
                        return (
                          <li>
                            <a href={href}>{repo}</a>
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                );
              })}
            </RepoCategories>
          </Prose>
        </StyledBackplate>
      </Layer>
    </Layout>
  );
};

export default ReposPage;

const StyledBackplate = styled.div`
  background-color: ${white};
  border-radius: ${borderRadius};
  padding: 1rem;

  ${mqMin('md')} {
    padding: 2rem;
  }
`;

const StyledTable = styled.table`
  width: 100%;
`;

const RepoCategories = styled.div`
  div {
    max-width: 50%;
    margin: 0 auto;
  }

  ${mqMin('md')} {
    display: flex;
    justify-content: center;
    div {
      max-width: 30%;
    }
  }
`;
